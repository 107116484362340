<template>
  <section v-if="hero" class="bg-dark-blue">
    <div class="positition-relative" style="overflow: hidden;">
      <VueSlickCarousel
        v-bind="settings"
        @beforeChange="hideText"
        @afterChange="quoteChange"
        @init="setFirstCompany"
        v-if="hero.companies"
        ref="carousel"
        :speed="600"
      >
        <div v-for="c in hero.companies" :key="c.company.slug">
          <div
            class="d-block cursor-pointer hero-home"
            :style="
              'background-image: url(' + baseUrl + c.company.hero.url + ');'
            "
          >
            <div
              class="position-absolute d-none d-xl-block"
              :style="'width:100%; bottom: -3%;'"
            >
              <div class="container-fluid">
                <div class="row align-items-end">
                  <div class="col-xl-9 col-12 text-white my-4">
                    <h1
                      class="text-white text-hero text-bold mb-4 lh-0"
                      v-if="hero"
                    >
                      <!-- {{ hero.title[lang] }} -->
                      Backing teams<br>accross all stages
                    </h1>
                    <router-link
                      :to="'/companies/' + c.company.slug"
                      class="btn bg-orange float-right mb-4"
                    >
                      {{ lang === "en" ? "More about us" : "Többet rólunk" }}
                    </router-link>
                  </div>
                  <div
                    class="col-xl-4 col-12 float-right  text-white align-bottom mb-4"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
    <div class="container-fluid bg-dark-blue">
      <div
        class="row text-white bg-dark-blue"
        style="margin-top: -7px/* -40px */;"
        v-if="selectedCompany"
      >
        <div class="col-12 my-3 px-4 d-block d-xl-none">
          <h1 class="text-white text-hero text-bold mb-4 lh-0" v-if="hero">
            {{ hero.title[lang] }}
          </h1>

          <router-link
            :to="'/companies/' + selectedCompany.company.slug"
            class="btn bg-orange float-right mb-2"
          >
            {{ lang === "en" ? "More about us" : "Többet rólunk" }}
          </router-link>
        </div>
        <div
          class="col-6 col-xl my-auto p-4 text-center cursor-pointer"
          v-for="(l, index) in hero.companies"
          :key="l.id"
          :class="
            selectedCompany.company.slug === l.company.slug
              ? 'bg-classic-blue '
              : 'bg-dark-blue'
          "
          @click="handleCompanyClick(index)"
        >
          <!-- <router-link :to="'/companies/' + l.company.slug"> -->
          <img
            v-bind:src="baseUrl + l.company.logo.url"
            alt="Company"
            class="hero-company-image"
          />
          <!-- </router-link> -->
        </div>
        <div
          class="col-12 col-xl my-auto p-4 text-center cursor-pointer bg-dark-blue text-white"
        >
          <router-link :to="'/companies'">
            <div class="position-relative hero-company-image">
              <h3 class="absolute-center text-white">
                {{ lang === "en" ? "All investments" : "További befektetések" }}
              </h3>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  props: [],
  data() {
    return {
      baseUrl: process.env.VUE_APP_STRAPI_URI,
      selectedCompany: "",
      hideTextBlock: false,
      settings: {
        arrows: true,
        dots: false,
        dotsClass: "slick-dots custom-dot-class",
        infinite: true,
        speed: 1500,
        autoplaySpeed: 7000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        fade: true,
        pauseOnHover: false,
      },
      width: window.innerWidth,
    };
  },
  computed: {
    ...mapState(["lang", "hero"]),
  },
  components: {
    VueSlickCarousel,
  },
  methods: {
    hideText(/* oldIndex, newIndex */) {
      this.hideTextBlock = true;
      /* this.selectedCompany = this.hero.companies[newIndex]; */
    },

    setFirstCompany() {
      this.selectedCompany = this.hero.companies[0];
    },

    quoteChange(slideIndex) {
      this.selectedCompany = this.hero.companies[slideIndex];
      this.hideTextBlock = false;
    },

    handleNavigation() {
      this.$router.push("/companies/" + this.selectedCompany.company.slug);
    },

    handleCompanyClick(index) {
      this.$refs.carousel.goTo(index);
    },
  },
};
</script>
