import Vue from "vue";
import Vuex from "vuex";
import * as axios from "axios";
import qs from "qs";
import router from "../router";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: false,
  state: {
    base: "",
    header: "",
    hero: "",
    stats: "",
    stages: "",
    faq: [],
    faqTitle: "",
    lang: "en",
    coworkerDecor: "",
    coworker: "",
    coworkers: [],
    roleGroups: [],
    company: "",
    companies: [],
    companiesDecor: "",
    highlightedCompany: "",
    articlesDecor: "",
    articles: [],
    articles_en: [],
    articles_hun: [],
    article: "",
    lifecycles: [],
    areas: [],
    quotesDecor: "",
    quotes: [],
    earlyStage: "",
    growthStage: "",
    buyOut: "",
    contact: "",
    contactUs: "",
    jobs: [],
    job: "",
    jobDecor: "",
    publications: [],
    legal: "",
    complaint: "",
    sustainability: "",
    loading: false,
    mainLoading: true,
  },
  mutations: {
    setBase: (state, base) => {
      state.base = base;
    },
    setHeader: (state, header) => {
      state.header = header;
    },
    setHero: (state, hero) => {
      state.hero = hero;
    },
    setStats: (state, stats) => {
      state.stats = stats;
    },
    setStages: (state, stages) => {
      state.stages = stages;
    },
    setFaq: (state, faq) => {
      state.faq = faq.QA;
      state.faqTitle = faq.title;
    },
    setLanguage: (state, lang) => {
      state.lang = lang;

      if (lang === "hun") {
        state.articles = state.articles_hun;
      } else if (lang === "en") {
        state.articles = state.articles_en;
      }
    },
    setCoworkerDecor: (state, coworkerDecor) => {
      state.coworkerDecor = coworkerDecor;
    },
    setCoworker: (state, coworker) => {
      state.coworker = coworker;
    },
    setCoworkers: (state, coworkers) => {
      state.coworkers = coworkers;
    },
    setRoleGroups: (state, roleGroups) => {
      state.roleGroups = roleGroups;
    },
    setCompany: (state, company) => {
      state.company = company;
    },
    setCompanies: (state, companies) => {
      state.companies = companies;
    },
    setCompaniesDecor: (state, companiesDecor) => {
      state.companiesDecor = companiesDecor;
    },
    setHighlightedCompany: (state, companyId) => {
      state.highlightedCompany = companyId;
    },
    setQuotesDecor: (state, quotesDecor) => {
      state.quotesDecor = quotesDecor;
    },
    setQuotes: (state, quotes) => {
      state.quotes = quotes;
      state.highlightedCompany = "";
    },
    setEarlyStage: (state, earlyStage) => {
      state.earlyStage = earlyStage;
    },
    setGrowthStage: (state, growthStage) => {
      state.growthStage = growthStage;
    },
    setBuyOut: (state, buyOut) => {
      state.buyOut = buyOut;
    },
    setArticlesDecor: (state, articlesDecor) => {
      state.articlesDecor = articlesDecor;
    },
    setArticles: (state, articles) => {
      var articles_en = [];
      var articles_hun = [];

      for (var i = 0; i < articles.length; i++) {
        if (state.article && state.article._id === articles[i]._id) {
          continue;
        }

        if (articles[i].description.en) {
          articles_en.push(articles[i]);
        }

        if (articles[i].description.hun) {
          articles_hun.push(articles[i]);
        }
      }

      articles_hun.sort(
        (a, b) =>
          b.displayPriority - a.displayPriority ||
          new Date(b.pubDate) - new Date(a.pubDate)
      );
      articles_en.sort(
        (a, b) =>
          b.displayPriority - a.displayPriority ||
          new Date(b.pubDate) - new Date(a.pubDate)
      );

      if (state.lang === "hun") {
        state.articles = articles_hun;
      } else if (state.lang === "en") {
        state.articles = articles_en;
      }

      state.articles_en = articles_en;
      state.articles_hun = articles_hun;
    },
    setArticle: (state, article) => {
      state.article = article;
    },
    setLifecycles: (state, lifecycles) => {
      state.lifecycles = lifecycles;
    },
    setAreas: (state, areas) => {
      state.areas = areas;
    },
    setContact: (state, contact) => {
      state.contact = contact;
    },
    setContactUs: (state, contactUs) => {
      state.contactUs = contactUs;
    },
    setJobs: (state, jobs) => {
      state.jobs = jobs;
    },
    setJob: (state, job) => {
      state.job = job;
    },
    setJobsDecor: (state, jobsDecor) => {
      state.jobDecor = jobsDecor;
    },
    setPublications: (state, publications) => {
      state.publications = publications;
    },
    setLegal: (state, legal) => {
      state.legal = legal;
    },
    setComplaint: (state, complaint) => {
      state.complaint = complaint;
    },
    setSustainability: (state, sustainability) => {
      state.sustainability = sustainability;
    },
    setLoadState: (state, isLoading) => {
      state.loading = isLoading;
    },
    setMainLoading: (state, loading) => {
      state.mainLoading = loading;
    },
  },
  actions: {
    getBase: (context) => {
      context.commit("setLoadState", true);

      const url = process.env.VUE_APP_STRAPI_URI + "/base";

      axios
        .get(url)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setBase", response.data);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getHeader: (context) => {
      context.commit("setLoadState", true);

      const url = process.env.VUE_APP_STRAPI_URI + "/header";

      axios
        .get(url)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setHeader", response.data.Links);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getHero: (context) => {
      context.commit("setLoadState", true);

      const url = process.env.VUE_APP_STRAPI_URI + "/hero";

      axios
        .get(url)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setHero", response.data);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getHeroGraph: (context) => {
      context.commit("setLoadState", true);
      const url = process.env.VUE_APP_STRAPI_GRAPH;

      var body = {
        query: `
                    { 
                        hero {
                            id
                            title {
                              en
                              hun
                            }
                            companies {
                              title {
                                en
                                hun
                              }
                              author {
                                en
                                hun
                              }
                              company {
                                id
                                name
                                slug
                                hero {
                                  url
                                }
                                logo {
                                  url
                                }
                              }
                            }
                          }
                    }
                `,
      };

      axios
        .post(url, body)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setHero", response.data.data.hero);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getStats: (context) => {
      context.commit("setLoadState", true);

      const url = process.env.VUE_APP_STRAPI_URI + "/stats";

      axios
        .get(url)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setStats", response.data);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getStages: (context) => {
      context.commit("setLoadState", true);

      const url = process.env.VUE_APP_STRAPI_URI + "/stages";

      axios
        .get(url)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setStages", response.data);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getFaq: (context) => {
      context.commit("setLoadState", true);

      const url = process.env.VUE_APP_STRAPI_URI + "/faq";

      axios
        .get(url)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setFaq", response.data);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getCoworkerDecor: (context) => {
      context.commit("setLoadState", true);

      const url = process.env.VUE_APP_STRAPI_URI + "/coworker-decor";

      axios
        .get(url)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setCoworkerDecor", response.data);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getCoworker: (context, id) => {
      context.commit("setCoworker", "");

      const url = process.env.VUE_APP_STRAPI_URI + "/coworkers?slug=" + id;

      axios
        .get(url)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setCoworker", response.data[0]);

          // Set coworker related data
          context.commit("setCompanies", response.data[0].companies);

          context.dispatch("getQuotesGraph", response.data[0]._id);
          var query = qs.stringify({ "author._id": response.data[0]._id });
          context.dispatch("getArticles", query);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getCoworkers: (context) => {
      context.commit("setLoadState", true);

      const url = process.env.VUE_APP_STRAPI_URI + "/coworkers";

      axios
        .get(url)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setCoworkers", response.data);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getCoworkersGraph: (context) => {
      context.commit("setLoadState", true);
      const url = process.env.VUE_APP_STRAPI_GRAPH;

      var body = {
        query: `
                    {
                        coworkers {
                            _id
                            slug
                            image {
                                url
                            }
                            firstName
                            lastName
                            title {
                                en
                                hun
                            }
                            rank
                            role_group {
                                en
                                hun
                            }
                            lifecycles {
                                _id
                                en
                                hun
                            }
                            areas { 
                                en
                                hun
                            }
                            calendly
                            displayPriority
                        }
                    }`,
      };

      axios
        .post(url, body)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setCoworkers", response.data.data.coworkers);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getRoleGroups: (context) => {
      context.commit("setLoadState", true);

      const url = process.env.VUE_APP_STRAPI_URI + "/role-groups";

      axios
        .get(url)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setRoleGroups", response.data);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getCompany: (context, id) => {
      context.commit("setLoadState", true);

      const url = process.env.VUE_APP_STRAPI_URI + "/companies/" + id;

      axios
        .get(url)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setCompany", response.data);

          // Get articles related to company
          var query = qs.stringify({ "companies._id": response.data._id });
          context.dispatch("getArticles", query);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getCompanyGraph: (context, id) => {
      context.commit("setCompany", "");

      const url = process.env.VUE_APP_STRAPI_GRAPH;

      var body = {
        query: `
                {
                    companies(where: { slug: "${id}" }) {
                      _id
                      slug
                      hero {
                        url
                      }
                      name
                      lifecycle {
                        en
                        hun
                      }
                      areas {
                        en
                        hun
                      }
                      coworkers {
                        _id
                        slug
                        firstName
                        lastName
                        displayPriority
                      }
                      website
                      description {
                        en
                        hun
                      }
                      events {
                        date
                        title {
                          en
                          hun
                        }
                        link {
                          en
                          hun
                          url
                        }
                        description {
                          en
                          hun 
                        }
                      }
                      articles {
                        _id
                        slug
                        hero {
                            url
                        }
                        author {
                          firstName
                          lastName
                        }
                        title {
                          en
                          hun
                        }
                        description {
                            en
                            hun
                        }
                        companies {
                            _id
                            name
                        }
                        lifecycles {
                          en
                          hun
                        }
                        areas {
                            en
                            hun
                        }
                        updatedAt
                        pubDate
                        displayPriority
                      }
                      invested
                      founders {
                          firstName
                          lastName
                      }
                      management {
                        firstName
                        lastName
                      }
                      createdAt
                    }
                }`,
      };

      axios
        .post(url, body)
        .then((response) => {
          context.commit("setCompany", response.data.data.companies[0]);
          context.commit(
            "setArticles",
            response.data.data.companies[0].articles
          );
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getCompanies: (context) => {
      context.commit("setLoadState", true);

      const url = process.env.VUE_APP_STRAPI_URI + "/companies";

      axios
        .get(url)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setCompanies", response.data);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getCompaniesDecor: (context) => {
      context.commit("setLoadState", true);

      const url = process.env.VUE_APP_STRAPI_URI + "/companies-decor";

      axios
        .get(url)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setCompaniesDecor", response.data);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getCompaniesGraph: (context, limit) => {
      context.commit("setLoadState", true);
      const url = process.env.VUE_APP_STRAPI_GRAPH;

      var body = {
        query: `
                    {
                        companies(limit: ${limit}) {
                            _id
                            slug
                            name
                            lifecycle {
                                _id
                                en
                                hun
                            }
                            logo {
                                url
                            }
                            hero {
                                url
                            }
                            displayPriority
                        }
                    }
                `,
      };

      axios
        .post(url, body)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setCompanies", response.data.data.companies);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getQuotesDecor: (context) => {
      context.commit("setLoadState", true);
      const url = process.env.VUE_APP_STRAPI_URI + "/quotes-decor";

      axios
        .get(url)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setQuotesDecor", response.data);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getQuotes: (context) => {
      context.commit("setLoadState", true);

      const url = process.env.VUE_APP_STRAPI_URI + "/quotes";

      axios
        .get(url)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setQuotes", response.data);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getQuotesGraph: (context, coworkerId) => {
      context.commit("setLoadState", true);
      const url = process.env.VUE_APP_STRAPI_GRAPH;

      var filter = "";

      if (coworkerId) {
        filter = `coworker: { _id:  "${coworkerId}"  } `;
      }

      var body = {
        query: `
                    {
                        quotes(where: { ${filter} }) {
                            _id
                            en
                            hun
                            coworker {
                              _id
                              firstName
                              lastName
                              title {
                                hun
                                en
                              }
                              image {
                                url 
                              }
                            }
                            company {
                              _id
                              slug
                              name
                              logo {
                                url
                              }
                              hero {
                                url
                              }
                              lifecycle {
                                  _id
                              }
                            }
                            displayPriority
                        }
                    }
                `,
      };

      axios
        .post(url, body)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setQuotes", response.data.data.quotes);
          context.commit(
            "setHighlightedCompany",
            response.data.data.quotes[0].company._id
          );
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getEarlyStage: (context) => {
      context.commit("setLoadState", true);

      const url = process.env.VUE_APP_STRAPI_URI + "/early-stage";

      axios
        .get(url)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setEarlyStage", response.data);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getGrowthStage: (context) => {
      context.commit("setLoadState", true);

      const url = process.env.VUE_APP_STRAPI_URI + "/growth-stage";

      axios
        .get(url)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setGrowthStage", response.data);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getBuyOut: (context) => {
      context.commit("setLoadState", true);

      const url = process.env.VUE_APP_STRAPI_URI + "/buy-out";

      axios
        .get(url)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setBuyOut", response.data);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getArticlesDecor: (context) => {
      context.commit("setLoadState", true);

      const url = process.env.VUE_APP_STRAPI_URI + "/articles-decor";

      axios
        .get(url)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setArticlesDecor", response.data);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getArticles: (context, params) => {
      context.commit("setLoadState", true);

      const url = process.env.VUE_APP_STRAPI_URI + `/articles?${params}`;

      axios
        .get(url)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setArticles", response.data);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getArticlesGraph: (context, params) => {
      const url = process.env.VUE_APP_STRAPI_GRAPH;

      var body = {
        query: `{
                    articles(limit: ${params.limit}, sort: "${params.sort}") {
                      _id
                      slug
                      hero { 
                          url
                      }
                      title {
                        en
                        hun
                      }
                      description {
                        en
                        hun
                      }
                      author {
                        firstName
                        lastName
                      }
                      companies {
                        slug
                        _id
                        name
                      }
                      lifecycles { 
                        en
                        hun
                      }
                      areas { 
                        en
                        hun
                      }
                      updatedAt
                      pubDate
                      displayPriority
                    }
                }`,
      };

      axios
        .post(url, body)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setArticles", response.data.data.articles);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getArticlesByLifecycle: (context, lifecycleId) => {
      const url = process.env.VUE_APP_STRAPI_GRAPH;

      var body = {
        query: `{
                    lifecycle(id: "${lifecycleId}") {
                      en
                      hun
                      articles {
                        _id
                        slug
                        hero { 
                            url
                        }
                        title {
                          en
                          hun
                        }
                        description {
                          en
                          hun
                        }
                        author {
                          firstName
                          lastName
                        }
                        companies {
                          slug
                          _id
                          name
                        }
                        lifecycles { 
                            en
                          hun
                        }
                        areas { 
                            en
                          hun
                        }
                        updatedAt
                        pubDate
                        displayPriority
                      }
                    }
                  }`,
      };

      axios
        .post(url, body)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setArticles", response.data.data.lifecycle.articles);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getArticlesByType: (context, type) => {
      const url = process.env.VUE_APP_STRAPI_GRAPH;

      var body = {
        query: `{
          articles(where: { type: "${type}" }) {
            _id
            slug
            hero { 
                url
            }
            title {
              en
              hun
            }
            description {
              en
              hun
            }
            author {
              firstName
              lastName
            }
            companies {
              slug
              _id
              name
            }
            lifecycles { 
              en
              hun
            }
            areas { 
              en
              hun
            }
            updatedAt
            pubDate
            displayPriority
          }
        }`,
      };

      axios
        .post(url, body)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setArticles", response.data.data.articles);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getArticlesByArea: (context, areaId) => {
      const url = process.env.VUE_APP_STRAPI_GRAPH;

      var body = {
        query: `{
                    area(id: "${areaId}") {
                      en
                      hun
                      articles {
                        _id
                        slug
                        hero { 
                            url
                        }
                        title {
                          en
                          hun
                        }
                        description {
                          en
                          hun
                        }
                        author {
                          firstName
                          lastName
                        }
                        companies {
                          slug
                          _id
                          name
                        }
                        lifecycles { 
                            en
                          hun
                        }
                        areas { 
                            en
                          hun
                        }
                        updatedAt
                        pubDate
                        displayPriority
                      }
                    }
                  }`,
      };

      axios
        .post(url, body)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setArticles", response.data.data.area.articles);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getArticle: (context, id) => {
      context.commit("setArticle", "");
      context.commit("setLoadState", true);

      const url = process.env.VUE_APP_STRAPI_URI + "/articles?slug=" + id;

      axios
        .get(url)
        .then((response) => {
          context.commit("setLoadState", false);

          if (response.data && response.data.length === 0) {
            return router.push("/");
          }

          context.commit("setArticle", response.data[0]);

          var filter = [];

          // If there is a company related to article -> fetch those
          if (response.data[0].companies.length > 0) {
            filter.push({
              "companies._id_in": response.data[0].companies[0]._id,
            });
          }

          if (response.data[0].author) {
            filter.push({ "author._id": response.data[0].author._id });
          }

          if (response.data[0].lifecycles.length > 0) {
            filter.push({
              "lifecycles.id_in": response.data[0].lifecycles[0]._id,
            });
          }

          var query = qs.stringify({ _where: { _or: filter }, _limit: 15 });

          context.dispatch("getArticles", query);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getLifecycles: (context) => {
      context.commit("setLoadState", true);
      const url = process.env.VUE_APP_STRAPI_GRAPH;

      var body = {
        query: `
                    { 
                        lifecycles {
                            _id
                            en
                            hun
                        }
                    }
                `,
      };

      axios
        .post(url, body)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setLifecycles", response.data.data.lifecycles);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getAreas: (context) => {
      context.commit("setLoadState", true);
      const url = process.env.VUE_APP_STRAPI_GRAPH;

      var body = {
        query: `
                    { 
                        areas {
                            _id
                            en
                            hun
                        }
                    }
                `,
      };

      axios
        .post(url, body)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setAreas", response.data.data.areas);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getContact: (context) => {
      context.commit("setLoadState", true);

      const url = process.env.VUE_APP_STRAPI_URI + "/contact";

      axios
        .get(url)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setContact", response.data);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getContactUs: (context) => {
      context.commit("setLoadState", true);

      const url = process.env.VUE_APP_STRAPI_URI + "/contact-us";

      axios
        .get(url)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setContactUs", response.data);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getJobs: (context) => {
      context.commit("setLoadState", true);

      const url = process.env.VUE_APP_STRAPI_URI + "/jobs";

      axios
        .get(url)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setJobs", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getJob: (context, id) => {
      context.commit("setLoadState", true);

      const url = process.env.VUE_APP_STRAPI_URI + "/jobs/" + id;

      axios
        .get(url)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setJob", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getJobsDecor: (context) => {
      context.commit("setLoadState", true);

      const url = process.env.VUE_APP_STRAPI_URI + "/job-decor";

      axios
        .get(url)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setJobsDecor", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getPublications: (context) => {
      context.commit("setLoadState", true);

      const url = process.env.VUE_APP_STRAPI_URI + "/publications";

      axios
        .get(url)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setPublications", response.data);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getLegal: (context) => {
      context.commit("setLoadState", true);

      const url = process.env.VUE_APP_STRAPI_URI + "/legal";

      axios
        .get(url)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setLegal", response.data);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getComplaint: (context) => {
      context.commit("setLoadState", true);

      const url = process.env.VUE_APP_STRAPI_URI + "/complaint-handling";

      axios
        .get(url)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit("setComplaint", response.data);
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },

    getSustainability: (context) => {
      const url = process.env.VUE_APP_STRAPI_GRAPH;

      var body = {
        query: `{
                    sustainability {
                        title {
                          en
                          hun
                        }
                        description {
                          en
                          hun
                        }
                        docs_en {
                          id
                          title
                          doc {
                            url
                          }
                        }
                        docs_hun {
                          id
                          title
                          doc {
                            url
                          }
                        }
                      }
                  }`,
      };

      axios
        .post(url, body)
        .then((response) => {
          context.commit("setLoadState", false);
          context.commit(
            "setSustainability",
            response.data.data.sustainability
          );
        })
        .catch((error) => {
          console.log(error);
          //store.dispatch('sendError', error);
        });
    },
  },
});
