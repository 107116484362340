<template>
    <div class="miami">
      <loading 
        :active.sync="mainLoading" 
        :opacity=".99"
        background-color="#A3CBE6"
        color="#52AE30"
        >
          <div class="semipolar-spinner">
            <div class="ring"></div>
            <div class="ring"></div>
            <div class="ring"></div>
            <div class="ring"></div>
            <div class="ring"></div>
          </div>
      </loading>
      <div class="all-area" v-if="hero">
        <HeaderSection color="blue"/>
        <HeroSection2 />
        <StatsSection />
        <StagesSection />
        <ArticleList v-if="restVisible" :showAll="true" :showDecor="true"/>
        <!-- <QuotesList v-if="restVisible" :highlight="'bg-red'" :dotColor="'dot-red'" :showButton="true" :showDecor="true"/> -->
        <CoworkerList v-if="restVisible" :showDecor="true" :roleGrouped="true"/>
        <!-- <FaqSection v-if="restVisible" /> -->
      </div>
    </div>
  </template>
  
  <script>
  import HeaderSection from '../components/Header/HeaderOne'
  /* import HeroSection from '../components/Hero/Home' */
  import HeroSection2 from '../components/Hero/Home2'
  import StatsSection from '../components/Stats/Home'
  import StagesSection from '../components/Stages/Stages';
  import CoworkerList from '../components/Coworkers/List';
  import ArticleList from '../components/Articles/List';
  /* import QuotesList from '../components/Quotes/List'; */
  /* import FaqSection from '../components/Faq/Faq'; */
      
  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css';
  
  import { mapState } from 'vuex';
  
  export default {
      name: 'ThemeOne',
      components: {
          HeaderSection,
          HeroSection2,
          StagesSection,
          CoworkerList,
          ArticleList,
          /* QuotesList, */
          /* FaqSection, */
          StatsSection,
          Loading
      },
      data() {
        return {
            heroLoading: false,
            restVisible: false
        }
      },
      computed: { 
          ...mapState(['lang', 'hero', 'mainLoading'])
      },
      methods: {
        onScroll() {
          if (window.scrollY > 100 && this.restVisible === false) {
            this.restVisible = true;
            // Get data related to home page
            this.$store.dispatch('getArticlesGraph', {
              limit: 25,
              sort: "displayPriority:desc"
            });
            this.$store.dispatch('getCompaniesGraph', 0);
            /* this.$store.dispatch('getQuotesGraph'); */
          }
        }
      },
      mounted() {
          setTimeout(() => {
            this.$store.commit('setMainLoading', false);
          },1500)
  
          this.$nextTick(function() {
              window.addEventListener('scroll', this.onScroll);
              this.onScroll(); // needed for initial loading on page
          });      
  
          // Remove article to not filter out from list
          this.$store.commit('setArticle', '');
          // Quotes should be reinit
          /* this.$store.commit('setQuotes', []); */
      }
  }
  </script>
  
  <style>
  
  </style>