<template>
    <footer class="section footer-area">
        <!-- Footer Top -->
        <cookie-law 
            theme="base--rounded" 
            :buttonText="acceptText"
            buttonClass="cookie-button cookie--accept"
            :buttonDecline="true"
            :buttonDeclineText="declineText"
            buttonDeclineClass="cookie-button cookie--decline"
            v-on:accept="acceptAll()"
            v-on:decline="acceptSelected()">
            <div slot="message">
                <div class="" v-if="lang === 'hun'">
                    <div class="text-bold mb-2">Ez a weboldal sütiket használ</div>
                    <div>Sütiket használunk a tartalmak és hirdetések személyre szabásához, közösségi funkciók biztosításához, valamint weboldalforgalmunk elemzéséhez. Ezenkívül közösségi média-, hirdető- és elemező partnereinkkel megosztjuk az Ön weboldalhasználatra vonatkozó adatait, akik kombinálhatják az adatokat más olyan adatokkal, amelyeket Ön adott meg számukra vagy az Ön által használt más szolgáltatásokból gyűjtöttek.</div>
                    <router-link class="mt-2 text-bold text-green text-underline" :to="'/data-privacy'">Részletek</router-link>
                </div>
                <div class="" v-if="lang === 'en'">
                    <div class="text-bold mb-2">This website uses cookies</div>
                    <div>We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.</div>
                    <router-link class="mt-2 text-bold text-green text-underline" :to="'/data-privacy'">Details</router-link>
                </div>
<!--                 <div class="mt-4">
                <div class="form-check form-check-inline mr-4">
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked disabled>
                    <label v-if="lang == 'en'" class="ml-2 form-check-label" for="flexSwitchCheckDefault">Necessary</label>
                    <label v-if="lang == 'hun'" class="ml-2 form-check-label" for="flexSwitchCheckDefault">Elengedhetetlen</label>
                </div> 
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="marketing" v-model="marketing">
                    <label class="ml-2 form-check-label" for="marketing">Marketing</label>
                </div> 
                </div> -->
            </div>
        </cookie-law>

        <div class="footer-top">
            <div class="container-full">
                <div class="row" id="map" v-if="width > 1199">
                    <div class="col p-0">
                        <div class="mapouter"><div class="gmap_canvas"><iframe title="map" width="100%" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=MOM%20bev%C3%A1s%C3%A1rl%C3%B3&t=&z=17&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe></div></div>
                    </div>
                </div>
                <div class="row align-items-end">
                    <div class="col-xl-4 p-0 footer-img bg-light-blue text-center" v-if="width > 1199">
                        <img src="/assets/img/hq_dotted.png" alt="Head Quarter" width="80%" class="pt-4"> 
                    </div>
                    <div class="col-xl-8 footer-main-content aling-items-end" id="footerContent" v-if="contact">
                        <div class="row" id="footerContact" ref="footerContact">
                            <div class="col bg-classic-blue">
                                <div class="row px-4 pt-4 pb-2">
                                    <div class="col">
                                      <h2>{{ contact.title[lang] }}</h2>
                                    </div>
                                </div>
                                <div class="row px-4 pb-2">
                                    <div class="col-lg-6">
                                        <div class="row">
                                            <div class="col-md-6 my-4">
                                                <p class="nav-item">{{ contact.address }}</p>
                                            </div>
                                            <div class="col-md-6 my-4">
                                                <a :href="'mailto:' + contact.email" class="nav-item">{{ contact.email }}</a>
                                            </div>
                                            <div class="col-md-6 my-4" v-if="contact.phone">
                                                <a :href="'tel:' + contact.phone" class="nav-item">{{ contact.phone }}</a>
                                            </div>
                                            <div class="col-md-6 my-4">
                                                <a class="nav-item" target="_blank" :href="contact.linkedin"><img src="/assets/img/linkedin.svg" alt="Linkedin" class="mr-2" height="26px"></a>
                                                <a v-if="contact.crunchbase" class="nav-item" target="_blank" :href="contact.crunchbase"><img src="/assets/img/cb.svg" alt="Crunchbase" height="26px"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="row">
                                            <div class="col-12">
                                                <p class="nav-item">{{ contact.pitchText[lang] }}</p>
                                            </div>
                                            <div class="col-12">
                                                <a class="btn bg-red bookPitchBtn my-4" target="_blank" :href="contact.applyButton.url">{{ contact.applyButton[lang] }}</a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="row" id="map" v-if="width < 1200">
                            <div class="col p-0">
                                <div class="mapouter"><div class="gmap_canvas"><iframe width="100%" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=MOM%20bev%C3%A1s%C3%A1rl%C3%B3&t=&z=17&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe></div></div>
                            </div>
                        </div>
                        <div class="row">
                          <div class="col contact-session bg-dark-blue">
                              <div class="row px-4 py-2">
                                  <div class="col-xl-9 col-9 my-4">
                                         <div class="float-left">
                                            <img v-if="lang === 'hun'" src="/assets/img/portfolion_logo.svg" alt="Portfolion">
                                            <img v-if="lang === 'en'" src="/assets/img/portfolion_logo_en.svg" alt="Portfolion">
                                        </div>
                                  </div>
                              </div>
                              <div class="row px-4 py-4">
                                    <div class="my-4 col-xl-3 col-4" :class="(index > 0 && (index + 1) % 3 === 0) ? 'offset-xl-right-3' : ''" v-for="(u, index) in contact.Links" :key="u._id">
                                        <router-link class="nav-item my-4" :class="index % 3 === 0 ? 'fw-7' : ''" :to="u.url">{{ u[lang] }}</router-link>
                                    </div>
                                    <div class="my-4 col-xl-3 col-4">
                                            <div class="">
                                                <a v-if="lang === 'en'" class="nav-item my-4 fw-7" target="_blank" rel="noopener noreferrer" href="https://strapi.portfolion.hu/uploads/etikai_bejelento_rendszer_0dfb0bfed9.pdf">Ethical reporting system</a>
                                                <a v-if="lang === 'hun'" class="nav-item my-4 fw-7" target="_blank" rel="noopener noreferrer" href="https://strapi.portfolion.hu/uploads/etikai_bejelento_rendszer_0dfb0bfed9.pdf">Etikai bejelentő rendszer</a>
                                            </div>
                                    </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    import { bootstrap } from 'vue-gtag'
    import CookieLaw from 'vue-cookie-law';
    import { mapState } from 'vuex';

    export default {
        components: { 
            CookieLaw 
        },
        data() {
            return {
                width: window.innerWidth,
                height: '',
                marketing: false
            }
        },
        computed: { 
            ...mapState(['lang', 'contact']),
            acceptText() {
                if (this.lang === 'hun') {
                    return 'Engedélyez';
                } else {
                    return 'Accept'
                }
            },
            declineText() {
                if (this.lang === 'hun') {
                    return 'Elutasít';
                } else {
                    return 'Decline'
                }
            }
        },
        methods: {
            changeLang(lang) {
                this.$store.commit('setLanguage', lang);
            },
            acceptAll() {
                this.enablePlugin()
            },
            acceptSelected() {
                if (this.marketing) {
                    this.enablePlugin()
                }
            },
            enablePlugin () {
                bootstrap()
            }
        },
        mounted() {
            window.addEventListener('resize', () => {         
                this.width = window.innerWidth       
            });

            if (!this.$store.state.contact) {
                this.$store.dispatch('getContact');
            }
        },
    }
</script>

<style>
.Cookie {
    z-index: 999 !important;
}
</style>